.sidebar{
    border-radius: 0 30px 30px 0;
    z-index: 5;
    height: 100vh;
    transition: all 1s;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
   
}
.sidebar-x{
    margin: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.sidebar-list{
    margin: 15px;
    cursor: pointer;
}
.sidebar-full{
    border-radius: 0 30px 30px 0;
    z-index: 5;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 15%;
}

.svg{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 40px;
    margin-left: 5px;
    height: 40px;
    cursor: pointer;
}
/* .active-svg{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #1731AC;
    color: white;
    opacity: 0.8;
} */
.navigation{
    position: absolute;
    top: 40%;
}
.span{
    width: 100%;
    padding: 10px;
    cursor: pointer;
}
.span-hover{
    display: none;
}
@media screen and (min-width: 1000px){
    .span:hover{
        background-color: #9ca7e0;
        padding: 10px;
        width: 100%;
        transition: all 0.5s;
    }
    .span:hover .span-hover{
        transition: all 0.5s;
        position: absolute;
        left: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        background-color: #9ca7e0;
        height: 60px;
        padding: 20px;
        color: #1731AC;
        border-radius:0 15px 15px 0;
        margin-top: -10px;
    }
    .span-active:hover .span-hover{
        transition: all 0.5s;
        position: absolute;
        left: 70px;
        border-radius:0 15px 15px 0;
        padding: 20px;
        display: flex;
        margin-top: -10px;
        justify-content: center;
        align-items: center;
        width: 100px;
        background-color: #1731AC;
        opacity: 0.95;
        color: white;
        height: 60px;
    }
}

.span-active{
    background-color: #1731AC;
    opacity: 0.8;
    color: white;
    padding: 10px;
    width: 100%;
}
.cpointer{
    cursor: pointer;
}

@media screen and (max-width:1000px) {
    .sidebar{
        display: none;
    }
    .sidebar-full{
        width: 30%;
    }
    .sidebar-logo{
        display: none;
    }
    .navigation{
        position: static;
    }
}
@media screen and (max-width:600px) {
    .sidebar{
        display: none;
    }
    .sidebar-full{
        width: 80%;
        font-size: large;
    }
}
@media screen and (max-width:300px) {
    .sidebar-full{
        width: 90%;
        
    }
}