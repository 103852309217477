.na-fallback{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.auth-fallback{
    margin-left: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 1000px){
    .auth-fallback{
        margin-left: 0;
    }
    
}