.topbar{
    z-index: 4;
    position: fixed;
    top: 0;
    width: 100%;
    border-radius: 0 0 30px 30px;
    height: 70px;
    background-color: white;
}
.lst-svg{
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    margin: auto;
    display: none;
    cursor: pointer;
}
.name{
    font-size: large;
    color: #1731AC;
    font-weight: 500;
    cursor: pointer;
    margin-right: 5%;
}
.top-logo{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    margin-top: 10px;
}
.cp{
    cursor: pointer;
}
.exit-top{ margin-right: 10px;}
@media screen and (max-width: 1000px) {
    .name{
        font-size: small;
        margin-right: 0;
    }
    .exit-top{
        width: 30px;
        height: 30px;
    }
    .top-logo{
        height: 60px;
        width: 60px;
    }
    .lst-svg{
        height: 30px;
        width: 30px;
        display: block;
    }
    .topbar{
        height: 60px;
    }
}
@media screen and (max-width: 400px) {
    .name{
        font-size: xx-small;
        margin-right: 5px;
    }
    .top-logo{
        height: 50px;
        width: 50px;
    }
}
@media screen and (max-width: 300px) {
    .name{
        display: none;
        margin-right: 5px;
    }
}