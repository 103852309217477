.approve{
    background-color: #1731AC;
    color: white;
    border: none;
    border-radius: 10px;
    width: 25%;
    margin: 20px;
    cursor: pointer;
    font-size: large;
}
.approve:hover{
    background-color: #0f2076;
    transition: 0.3s;
}
.cancel{
    background-color: white;
    color: #1731AC;
    border: none;
    outline: solid 1px rgb(223, 223, 223);
    border-radius: 10px;
    width: 25%;
    margin: 20px;
    cursor: pointer;
    font-size: large;
}
.cancel:hover{
    background-color: rgb(226, 224, 224);
    transition: 0.3s;
}
@media screen and (max-width: 300px) {
    .approve{
        width: 40%;
    }
    .cancel{
        width: 40%;
    }
    
}