.nf-page{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.nf-image{
    width: 100%;
    height: auto;
}
.nf-logo{
    margin-left: 50px;
}
.nf-text{
    color: #1731ac;
    font-size: 1.5vw;
    text-align: center;
}
.error{
    width: 25%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 60%;
    text-align: center;
}
@media screen and (max-width:1200px) {
    .error{
        width: 40%;
        height: 45%;
        margin-top: 200px;
    }
    .nf-text{
        font-size: 2.5vw;
    }
} 
@media screen and (max-width:600px) {
    .error{
        width: 60%;
        margin-top: 200px;
        height: 30%;
    }
    .nf-text{
        font-size: 4vw;
    }
} 
@media screen and (max-width:400px) {
    .error{
        width: 80%;
        margin-top: 200px;
        height: 30%;
    }
    .nf-logo{
        margin-left: 20px;
    }
    .nf-text{
        font-size: 5vw;
    }
    .nf-link{
        font-size: small;
    }
} 

