.app{
    height: 100vh;
    overflow:"hidden";
    font-family: "roboto";
    background-color: white;
}
html{
    background-color: white;
}body{
    height: 100vh;
    overflow:"hidden";
    font-family: "roboto";
    background-color: white;
}
.cp{
    cursor: pointer;
}
.blue-fon-modal {
    position: fixed;
    background-color:#1731AC;
    opacity: 0.8;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: none;
}
.modal-open .blue-fon-modal {
    display: block;
}
@font-face {
    font-family: "roboto";
    src: url("../shared/fonts/roboto/Roboto-Medium.ttf");
}
@font-face {
    font-family: "roboto-tiny";
    src: url("../shared/fonts/roboto/Roboto-Light.ttf");
}